import { useEffect, useState } from "react";

import classes from "./ContentCard.module.scss";
import ButtonIcon from "../../ui/buttonIcon/ButtonIcon";
import ContentModel from "../../../models/ContentModel";
import Card from "../../ui/card/Card";
import ContentHelper from "../../../utils/ContentHelper";
import CustomInput from "../../ui/inputs/customInput/CustomInput";
import Loader from "../../ui/loader/Loader";

function ContentCard({
  onEdit,
  onDelete,
  content,
  selectedItems,
  onSelectItems = () => {},
  onSetIsDragging,
  setHoveredCategoryId,
  ghostName,
  className,
  children,
  style,
  selectable,
  type,
  onEditContentRank,
  isModalLoading,
}) {
  const contentModel = new ContentModel(content);
  const data = contentModel.data;

  const name = contentModel.getNameByLang();
  const image = contentModel.getPosterImage();
  // const image = contentModel.getContentPosterImage(type);
  // const rank = contentModel.data?.ranking;
  const cats = data?.liveTvGenres.length > 0 ? data?.liveTvGenres : data?.genre;

  const isSelected = selectable ? selectedItems?.includes(data) : false;

  const [rank, setRank] = useState(
    type === "row" ? data?.orderInPageRow : data?.orderInVertical
  );

  const changeRankHandler = (e) => {
    setRank(e.target.value);
  };

  const clickCardHandler = (e) => {
    e.stopPropagation();
    onSelectItems(data);
  };

  const onEditHandler = (e) => {
    e.stopPropagation();
    onEdit();
  };

  // const onDeleteHandler = (e) => {
  //   e.stopPropagation();
  //   onDelete();
  // };

  const setDragImageHandler = (e) => {
    const element = document.createElement("div");
    element.id = "ghost";
    const ghostContentRow = document.createElement("div");
    const ghostContentLabel = document.createElement("label");
    const ghostContentSpan = document.createElement("span");
    ghostContentLabel.textContent = ghostName + ": ";
    ghostContentSpan.textContent = selectedItems.length + (!isSelected ? 1 : 0);
    element.style.cssText = `width: 16rem;
    height: 4rem;
    border-radius: 6px;
    background-image: linear-gradient(
      100deg,
      var(--secondary-400),
      var(--primary-400) 89%
    );
    position: absolute;
    top: -64px;
    left: -256px;
    opacity: 1;`;
    ghostContentRow.style.cssText = `padding: 1rem 1.5rem;
    color: var(--white);
    font-family: "ManropeExtraBold";`;
    ghostContentSpan.style.cssText = `margin-left: 1.5rem;`;
    ghostContentRow.appendChild(ghostContentLabel);
    ghostContentRow.appendChild(ghostContentSpan);
    element.appendChild(ghostContentRow);
    document.body.appendChild(element);
    e.dataTransfer.setDragImage(element, 0, 0);
  };

  const dragStartHandler = (e) => {
    // select the item when dragging
    // if (!isSelected) {
    //   onSelectItems(data);
    // }

    setDragImageHandler(e);

    // e.dataTransfer.setData("selectedItems", JSON.stringify(selectedItems));

    onSetIsDragging(true);
  };

  const dragEndHandler = () => {
    const ghost = document.getElementById("ghost");
    if (ghost) {
      document.body.removeChild(ghost);
    }
    setHoveredCategoryId();
    onSetIsDragging(false);
  };

  useEffect(() => {
    setRank(type === "row" ? data?.orderInPageRow : data?.orderInVertical);
  }, [content]);

  return (
    <>
      <Card
        className={`${classes.card} ${className}`}
        style={{
          backgroundImage: !isSelected && `url('${image}')`,
          borderBottom: isSelected ? "6px solid var(--primary-400)" : "none",
          ...style,
        }}
        onClick={clickCardHandler}
        // draggable
        // onDragStart={dragStartHandler}
        // onDragEnd={dragEndHandler}
      >
        {!isSelected && children}
        <div
          className={classes.nameContainer}
          style={{ opacity: isSelected ? 1 : "" }}
        >
          {/* {rank!==0 && (
            <span className={classes.rank}></span>
          )} */}
          {(type === "vertical" || type === "row") &&
            (isModalLoading ? (
              <div className={classes.loaderContainer}>
                <Loader positionNotFixed={true} className={classes.loader} />
              </div>
            ) : (
              <CustomInput
                value={rank}
                onChange={changeRankHandler}
                onKeyDown={(e) => onEditContentRank(e, rank, data?.id)}
                className={classes.rankCustomInput}
                inputClassName={classes.rankInput}
                type={"number"}
              />
            ))}
          <span>{name}</span>
        </div>
        {onEdit && (
          <div className={classes.catsContainer}>
            {cats?.map((cat) => (
              <span key={cat?.id || cat?.name} className={classes.cat}>
                {data?.genre.length > 0
                  ? cat?.name
                  : ContentHelper.getNameByLang(cat)}
              </span>
            ))}
          </div>
        )}
        {!isSelected && (
          <div className={classes.actionsContainer}>
            {/* <ButtonIcon
              imagePath="card-buttons/edit"
              activeImagePath="card-buttons/edit-active"
              onClick={onEditHandler}
            />
            <ButtonIcon
              imagePath="card-buttons/delete"
              activeImagePath="card-buttons/delete-active"
              onClick={onDeleteHandler}
            /> */}
            {onEdit && (
              <ButtonIcon
                imagePath="card-buttons/show"
                activeImagePath="card-buttons/show-active"
                onClick={onEditHandler}
              />
            )}
          </div>
        )}
      </Card>
    </>
  );
}

export default ContentCard;
