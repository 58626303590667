import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { t } from "i18next";

import classes from "./Verticals.module.scss";
import Loader from "../../../../components/ui/loader/Loader";
import MenuCardLayout from "../../../../components/menuCardLayout/MenuCardLayout";
import CategoryItem from "../../../../components/menuItems/categoryItem/CategoryItem";
import NoItemsView from "../../../../components/ui/noItemsView/NoItemsView";
import ContentCard from "../../../../components/contentCards/contentCard/ContentCard";
import useFetchData from "../../../../hooks/useFetchData";
import verticalManager from "../../../../backend/managers/VerticalManager";
import ConfirmationModal from "../../../../components/modals/confirmationModal/ConfirmationModal";
import ContentHelper from "../../../../utils/ContentHelper";
import VerticalModal from "../../../../components/modals/verticalModal/VerticalModal";
import SortHelper from "../../../../utils/SortHelper";
import ButtonIcon from "../../../../components/ui/buttonIcon/ButtonIcon";
import MainHeader from "../../../../components/layout/mainHeader/MainHeader";
import LinkContentsModal from "../../../../components/modals/linkContentsModal/LinkContentsModal";
import { errorActions } from "../../../../store/reducers/Error-slice";

function Verticals() {
  const [isLoading, setIsLoading] = useState(false);
  const [verticals, setVerticals] = useState([]);
  const [selectedVertical, setSelectedVertical] = useState();
  const [isConfModalOpen, setIsConfModalOpen] = useState(false);
  const [isVerticalModalOpen, setIsVerticalModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isLinkVodsModalOpen, setIsLinkVodsModalOpen] = useState(false);

  const dispatch = useDispatch();

  const {
    data,
    isLoading: loading,
    onRefresh,
  } = useFetchData(verticalManager.getVerticalsApi);

  useEffect(() => {
    setVerticals(SortHelper.arrangeData(data, false, "verticalRank"));
    data &&
      setSelectedVertical((prevState) =>
        prevState ? data?.find((item) => item?.id === prevState?.id) : data[0]
      );
    setIsLoading(loading);
    // eslint-disable-next-line
  }, [data]);

  const menuButtons = [
    {
      id: "modify",
      imagePath: "icons/modify",
      activeImagePath: "icons/modify-active",
      disabled: !selectedVertical,
      action: () => {
        setIsVerticalModalOpen(true);
        setIsEditing(true);
      },
    },
    {
      id: "delete",
      imagePath: "icons/delete",
      activeImagePath: "icons/delete-active",
      disabled: !selectedVertical,
      action: () => setIsConfModalOpen(true),
    },
    {
      id: "add",
      imagePath: "icons/add",
      activeImagePath: "icons/add-active",
      action: () => {
        setIsVerticalModalOpen(true);
        setIsEditing(false);
      },
    },
  ];

  const deleteVerticalHandler = async () => {
    setIsModalLoading(true);
    const result = await verticalManager.deleteVerticalApi(
      selectedVertical?.elementId
    );
    if (result?.status === 0) {
      onRefresh();
      setSelectedVertical(data && data[0]);
    } else {
      dispatch(errorActions.setErrorMessage(result?.message || result));
    }
    setIsConfModalOpen(false);
    setIsModalLoading(false);
  };

  const callAPi = async (api, verticalData) => {
    setIsModalLoading(true);
    const result = await api(verticalData);
    if (result?.status === 0) {
      onRefresh();
    } else {
      dispatch(errorActions.setErrorMessage(result?.message || result));
    }
    setIsVerticalModalOpen(false);
    setIsModalLoading(false);
  };

  const onSubmitVerticalHandler = async (titles, rank, isDefault) => {
    const verticalData = { values: titles, rank, isDefault };
    if (isEditing) {
      verticalData["elementId"] = selectedVertical?.elementId;
    }
    await callAPi(
      isEditing
        ? verticalManager.updateVerticalApi
        : verticalManager.createVerticalApi,
      verticalData
    );
  };

  const selectItemsHandler = (item) => {
    if (!selectedItems.includes(item)) {
      setSelectedItems((prevState) => [...prevState, item]);
    } else {
      setSelectedItems((prevState) =>
        prevState.filter((c) => c.id !== item.id)
      );
    }
  };

  const unselectAllHandler = () => {
    setSelectedItems([]);
  };

  const openLinkVodsModalHandler = () => {
    selectedVertical && setIsLinkVodsModalOpen(true);
  };

  const linkVodsToRowHandler = async (vodIdsToBeAdded, vodIdsToBeRemoved) => {
    setIsModalLoading(true);
    if (vodIdsToBeAdded?.length > 0) {
      const result1 = await verticalManager.attachVodsApi(
        selectedVertical?.id,
        vodIdsToBeAdded
      );
      if (result1?.status !== 0) {
        dispatch(errorActions.setErrorMessage(result1?.message || result1));
        setIsModalLoading(false);
        return;
      }
    }
    if (vodIdsToBeRemoved?.length > 0) {
      const result2 = await verticalManager.detachVodsApi(
        selectedVertical?.id,
        vodIdsToBeRemoved
      );
      if (result2?.status !== 0) {
        dispatch(errorActions.setErrorMessage(result2?.message || result2));
        setIsModalLoading(false);
        return;
      }
    }
    if (vodIdsToBeAdded?.length === 0 && vodIdsToBeRemoved?.length === 0) {
      setIsModalLoading(false);
      return;
    }
    setIsModalLoading(false);
    setIsLinkVodsModalOpen(false);
    onRefresh();
  };

  const editContentRankHandler = async (e, rank, vodId) => {
    if (e.key === "Enter") {
      setIsModalLoading(true);
      const result = await verticalManager.updateRankApi({
        verticalId: selectedVertical?.id,
        vodId,
        rank,
      });
      if (result?.status === 0) {
        onRefresh();
      } else {
        dispatch(errorActions.setErrorMessage(result?.message || result));
      }
      setIsModalLoading(false);
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className={classes.page} onClick={unselectAllHandler}>
      <MainHeader />
      <MenuCardLayout
        pageTitle={t("verticals")}
        noDisplayAll={true}
        className={classes.menuLayout}
        cardClassName={classes.cardMenu}
        buttons={menuButtons}
      >
        <div className={classes.btnsContainer}>
          <ButtonIcon
            imagePath={"buttons/add"}
            activeImagePath={"buttons/add-active"}
            className={classes.btn}
            onClick={openLinkVodsModalHandler}
          />
        </div>
        {verticals?.map((vertical) => {
          return (
            <CategoryItem
              className={`${classes.item} ${
                vertical?.isDefault ? classes.bold : ""
              }`}
              key={vertical?.id}
              id={vertical?.id}
              name={vertical?.pickerName}
              number={vertical?.vods?.length}
              onClick={() => {
                setSelectedVertical(vertical);
              }}
              selectedCategoryId={selectedVertical?.id}
            />
          );
        })}
        {selectedVertical && selectedVertical?.vods?.length > 0 ? (
          <ul className={classes.list}>
            {selectedVertical?.vods?.map((vod) => (
              <ContentCard
                key={vod?.id}
                className={classes.itemCard}
                content={vod}
                type={"vertical"}
                onEditContentRank={editContentRankHandler}
                isModalLoading={isModalLoading}
              />
            ))}
          </ul>
        ) : (
          !isLoading && <NoItemsView />
        )}
      </MenuCardLayout>
      {isConfModalOpen && (
        <ConfirmationModal
          isModalOpen={isConfModalOpen}
          onClose={() => setIsConfModalOpen(false)}
          onConfirm={deleteVerticalHandler}
          text={t("delete_type", {
            type: t("vertical_lowerCase"),
          })}
          description={t("delete_type_conf_message", {
            type: t("vertical_lowerCase"),
          })}
          name={ContentHelper.getNameByLang(selectedVertical)}
          btnConfirmTitle={t("yes")}
          btnCancelTitle={t("cancel")}
          isLoading={isModalLoading}
        />
      )}
      {isVerticalModalOpen && (
        <VerticalModal
          isModalOpen={isVerticalModalOpen}
          onClose={() => setIsVerticalModalOpen(false)}
          vertical={isEditing && selectedVertical}
          isEditing={isEditing}
          onSubmit={onSubmitVerticalHandler}
          isModalLoading={isModalLoading}
        />
      )}
      {isLinkVodsModalOpen && (
        <LinkContentsModal
          isModalOpen={isLinkVodsModalOpen}
          onClose={() => setIsLinkVodsModalOpen(false)}
          isModalLoading={isModalLoading}
          typeData={selectedVertical}
          onSubmit={linkVodsToRowHandler}
          api={verticalManager.getContentsApi}
          getOriginalDataSource={verticalManager.getOriginalVods}
          title={t("add_vods_to_type", {
            type: ContentHelper.getNameByLang(selectedVertical),
          })}
          initialContents={selectedVertical?.vods}
        />
      )}
    </div>
  );
}

export default Verticals;
