import { useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { t } from "i18next";

import classes from "./Layout.module.scss";
import Footer from "./footer/Footer";
import MainHeader from "./mainHeader/MainHeader";
import SideBarNavigation from "./sidebarNavigation/SidebarNavigation";
import Modal from "../ui/modal/Modal";
import { errorActions } from "../../store/reducers/Error-slice";

function Layout() {
  // Check if the user is authenticated
  const isAuthenticated = !!Cookies.get("token");
  const userData = JSON.parse(localStorage.getItem("userData"));

  const error = useSelector((state) => state.error.errorMessage);
  const dispatch = useDispatch();

  const closeHandler = () => {
    dispatch(errorActions.setErrorMessage(""));
  };

  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  // Redirect to '/dashboard' if the current path is '/' and the user is authenticated
  useEffect(() => {
    if (currentPath === "/" && isAuthenticated) {
      navigate("/dashboard");
    }
    // eslint-disable-next-line
  }, [currentPath, navigate]);

  useEffect(() => {
    if (
      error === "Account's password is temporary, updating password is required"
    ) {
      Cookies.remove("token");
      navigate(`/login?email=${userData?.email}`);
      localStorage.clear();
    }
  }, [error]);

  return (
    <>
      {/* Error popup */}
      {error && (
        <Modal
          className={classes.modal}
          backdropClassName={classes.backdrop}
          isOpen={error ? true : false}
          onClose={closeHandler}
          top={"40%"}
        >
          <h3>{t("warning")}</h3>
          <div>{error}</div>
        </Modal>
      )}
      {/* If user is not authenticated, redirect to login */}
      {!isAuthenticated ? (
        <>
          {<Navigate to="/login" />}
          <Outlet />
        </>
      ) : (
        // Layout structure for authenticated users
        <div className={classes.colGridLayout}>
          <SideBarNavigation />
          <div className={classes.rowGridLayout}>
            <main className={classes.main}>
              {currentPath !== "/verticals" && <MainHeader />}
              <Outlet /> {/* Render child routes */}
            </main>
            <Footer />
          </div>
        </div>
      )}
    </>
  );
}

export default Layout;
