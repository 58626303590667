import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { t } from "i18next";

import classes from "./Messages.module.scss";
import styles from "../../../styles/CardRowCell.module.scss";
import MenuCardLayout from "../../../components/menuCardLayout/MenuCardLayout";
import MessageItem from "../../../components/message/messageItem/MessageItem";
import MessageContent from "../../../components/message/messageContent/MessageContent";
import messageManager from "../../../backend/managers/MessageManager";
import AddMessageModal from "../../../components/modals/addMessageModal/AddMessageModal";
import useFetchData from "../../../hooks/useFetchData";
import SearchInput from "../../../components/ui/inputs/searchInput/SearchInput";
import Button from "../../../components/ui/button/Button";
import ConfirmationModal from "../../../components/modals/confirmationModal/ConfirmationModal";
import ContentHelper from "../../../utils/ContentHelper";
import Loader from "../../../components/ui/loader/Loader";
import { errorActions } from "../../../store/reducers/Error-slice";
// import EditMessageModal from "../../../components/modals/editMessageModal/EditMessageModal";

function Messages() {
  const [messages, setMessages] = useState([]);
  const [typedMessages, setTypedMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState();
  const [selectedMessageTypeIndex, setSelectedMessageTypeIndex] = useState(0);
  const [isAddMessageModalOpen, setIsAddMessageModalOpen] = useState(false);
  const [isEditMessageModalOpen, setIsEditMessageModalOpen] = useState(false);
  const [isDuplicated, setIsDuplicated] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [isContentLoading, setIsContentLoading] = useState(false);
  const [isAddMessageLoading, setIsAddMessageLoading] = useState(false);
  const [isConfModalOpen, setIsConfModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const {
    data,
    onRefresh: refreshMessages,
    isLoading: loading,
  } = useFetchData(messageManager.getMessagesApi);

  const messagePermissions = JSON.parse(localStorage.getItem("userData"))
    ?.permissions?.messages;

  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(loading);
    setTypedMessages(data);
    if (data && data.length > 0) {
      // if page is refreshed
      if (selectedMessageTypeIndex) {
        const messages = data[selectedMessageTypeIndex]?.getMessages();
        setMessages(messages || []);
        setSelectedMessage(messages[0]);
      } else {
        const messages = data[0]?.getMessages();
        setMessages(messages || []);
        setSelectedMessage(messages[0]);
        fetchContentMessage(messages[0]?.id);
      }
    }
    // eslint-disable-next-line
  }, [data]);

  const openAddMessageModalHandler = () => {
    setIsAddMessageModalOpen(true);
  };

  const closeAddMessageModalHandler = () => {
    setIsAddMessageModalOpen(false);
    setIsDuplicated(false);
  };

  const openEditMessageModalHandler = () => {
    setIsEditMessageModalOpen(true);
  };

  const closeEditMessageModalHandler = () => {
    setIsEditMessageModalOpen(false);
  };

  const searchMessageHandler = (inputValue) => {
    setSearchInputValue(inputValue);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const searchedMessages = data[selectedMessageTypeIndex]
        ?.getMessages()
        .filter(
          (m) =>
            m
              ?.getTitleByLang()
              ?.toLocaleLowerCase()
              ?.includes(searchInputValue.toLocaleLowerCase()) ||
            m
              ?.getContentByLang()
              ?.toLocaleLowerCase()
              ?.includes(searchInputValue.toLocaleLowerCase())
        );
      setMessages(searchedMessages);
      const message = searchedMessages?.length > 0 && searchedMessages[0];
      const id = message?.id;
      id && fetchContentMessage(id);
      setSelectedMessage(message);
    }, 500);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, [searchInputValue]);

  const clearInputHandler = () => {
    setSearchInputValue("");
  };

  const selectedMessageId = selectedMessage?.id;

  const fetchContentMessage = async (id = selectedMessageId) => {
    setIsContentLoading(true);
    const result = await messageManager.getMessageByIdApi(id);
    if (result?.status === 0) {
      setSelectedMessage((prevState) => {
        return { ...prevState, ...result?.data };
      });
    }
    setIsContentLoading(false);
  };

  const selectMessageItemHandler = (message) => {
    if (selectedMessage?.id !== message?.id) {
      setSelectedMessage(message);
      fetchContentMessage(message?.id);
    }
  };

  const selectMessagesByTypesHandler = (index) => {
    const typeMessage = typedMessages[index];
    const messages = typeMessage?.getMessages();
    setMessages(messages);
    if (messages && messages.length > 0) {
      setSelectedMessage(messages[0]);
      fetchContentMessage(messages[0]?.id);
    }
    setSelectedMessageTypeIndex(index);
    setSearchInputValue("");
  };

  const duplicateMessageHandler = () => {
    setIsAddMessageModalOpen(true);
    setIsDuplicated(true);
  };

  const createMessageHandler = async (data) => {
    setIsAddMessageLoading(true);
    const result = await messageManager.createMessageApi(data);
    if (result?.status === 0) {
      setIsAddMessageModalOpen(false);
      refreshMessages();
    } else {
      dispatch(errorActions.setErrorMessage(result?.message || result));
    }
    setIsAddMessageLoading(false);
  };

  const deleteMessageHandler = async () => {
    setIsContentLoading(true);
    const result = await messageManager.deleteMessageApi(selectedMessageId);
    if (result.status === 0) {
      refreshMessages();
      setIsConfModalOpen(false);
    }
    setIsContentLoading(false);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <MenuCardLayout
        pageTitle={t("messages")}
        displayAllNumber={
          typedMessages &&
          typedMessages.length > 0 &&
          typedMessages[0].getValues()
        }
        isDisplayAllSelected={selectedMessageTypeIndex === 0}
        onSelectDisplayAll={() => selectMessagesByTypesHandler(0)}
      >
        <div className={classes.btnsContainer}>
          {messagePermissions?.types?.length > 0 && (
            <Button
              className={classes.add}
              title={"+ " + t("add")}
              onClick={openAddMessageModalHandler}
            />
          )}
        </div>
        {typedMessages?.map(
          (typeMessage, index) =>
            typeMessage.data.type !== "display_all" && (
              <div
                key={typeMessage.data.type}
                className={`${styles.cardRowCell} ${
                  index === selectedMessageTypeIndex && styles.active
                }`}
                onClick={() => selectMessagesByTypesHandler(index)}
              >
                <span className={styles.cardRowCellName}>
                  {typeMessage.getName()}
                </span>
                <span className={styles.cardRowCellCount}>
                  {typeMessage.getValues()}
                </span>
              </div>
            )
        )}
        <div className={classes.container}>
          <div
            className={classes.messageContainer}
            style={{ height: window.innerHeight - 250 }}
          >
            <div className={classes.searchInputContainer}>
              <SearchInput
                value={searchInputValue}
                onChangeInput={searchMessageHandler}
                onClear={clearInputHandler}
              />
            </div>
            {messages && messages.length > 0 ? (
              <ul className={classes.messagesList}>
                {messages?.map((message) => (
                  <MessageItem
                    key={message.id}
                    message={message}
                    onSelectMessageItem={selectMessageItemHandler}
                    selectedMessageId={selectedMessage?.id}
                    onRefresh={refreshMessages}
                  />
                ))}
              </ul>
            ) : (
              <div className={classes.noMessage}>
                <img
                  alt={t("noFile")}
                  src={require("../../../assets/img/no-file.png")}
                />
                <span>{t("noResultFound")}</span>
              </div>
            )}
          </div>
          {messages && messages.length > 0 ? (
            <MessageContent
              messageData={selectedMessage}
              onDuplicateMessage={duplicateMessageHandler}
              onOpenEditMessage={openEditMessageModalHandler}
              onRefresh={refreshMessages}
              isLoading={isContentLoading}
              onDeleteMessage={() => setIsConfModalOpen(true)}
              hideActions={messagePermissions?.types?.length === 0}
            />
          ) : (
            <img
              alt={t("noContent")}
              src={require("../../../assets/img/message/no-content-message.png")}
              className={classes.noContentImg}
            />
          )}
        </div>
      </MenuCardLayout>
      {isAddMessageModalOpen && (
        <AddMessageModal
          isModalOpen={isAddMessageModalOpen}
          onClose={closeAddMessageModalHandler}
          content={isDuplicated ? selectedMessage : null}
          onCreateMessage={createMessageHandler}
          isAddMessageLoading={isAddMessageLoading}
          allowedMessageTypes={messageManager.getAllowedMessageTypes(
            messagePermissions?.types
          )}
        />
      )}
      {isConfModalOpen && (
        <ConfirmationModal
          isModalOpen={isConfModalOpen}
          onClose={() => setIsConfModalOpen(false)}
          onConfirm={deleteMessageHandler}
          text={t("delete_type", {
            type: t("message_lowerCase"),
          })}
          description={t("delete_type_conf_message", {
            type: t("message_lowerCase"),
          })}
          name={ContentHelper.getNameByLang(selectedMessage)}
          btnConfirmTitle={t("yes")}
          btnCancelTitle={t("cancel")}
          isLoading={isContentLoading}
        />
      )}
      {/* {isEditMessageModalOpen && (
        <EditMessageModal
          isModalOpen={isEditMessageModalOpen}
          onClose={closeEditMessageModalHandler}
          content={selectedMessage?.getContentByLang()}
        />
      )} */}
    </>
  );
}

export default Messages;
