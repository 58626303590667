import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

import classes from "./Customers.module.scss";
import CustomerTableRow from "../../../components/table/tableRow/CustomerTableRow";
import SearchSortView from "../../../components/ui/searchSort/searchSortView/SearchSortView";
import ButtonIcon from "../../../components/ui/buttonIcon/ButtonIcon";
import SortHelper from "../../../utils/SortHelper";
import CustomerCard from "../../../components/contentCards/customerCard/CustomerCard";
import NoItemsView from "../../../components/ui/noItemsView/NoItemsView";
import customerManager from "../../../backend/managers/CustomerManager";
import useFetchData from "../../../hooks/useFetchData";
import Loader from "../../../components/ui/loader/Loader";

function Customers() {
  const SORT_OPTIONS = SortHelper.getSortOptions();
  const tableHeaders = customerManager.getTableHeaders();

  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [selectedSortType, setSelectedSortType] = useState(SORT_OPTIONS[0]);
  const [isDesc, setIsDesc] = useState(false);
  const [displayGrid, setDisplayGrid] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");

  const navigate = useNavigate();

  const { data, isLoading: loading } = useFetchData(
    customerManager.getCustomersApi
  );

  useEffect(() => {
    setCustomers(SortHelper.arrangeData(data, false, "customerName"));
    setIsLoading(loading);
  }, [data]);

  const arrangeTableCustomersHandler = (thId) => {
    setIsDesc((prevState) => !prevState);
    setCustomers((prevState) =>
      SortHelper.arrangeData(prevState, !isDesc, thId)
    );
  };

  useEffect(() => {
    setCustomers((prevState) =>
      displayGrid
        ? [
            ...SortHelper.sortData(
              prevState,
              selectedSortType?.id,
              "customerName"
            ),
          ]
        : SortHelper.arrangeData(data, false, "customerName")
    );
    // eslint-disable-next-line
  }, [displayGrid, selectedSortType]);

  useEffect(() => {
    if (!searchInputValue) {
      setCustomers(data);
      return;
    }
    const timeoutId = setTimeout(() => {
      setCustomers(customerManager.searchLocally(searchInputValue));
    }, 500);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, [searchInputValue]);

  return (
    <>
      <h2>{t("customers")}</h2>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={classes.sortingContainer}>
            <SearchSortView
              hasSort={displayGrid}
              className={classes.searchSortView}
              expButtonClassName={classes.expButton}
              searchInputClassName={classes.searchInput}
              options={SORT_OPTIONS}
              selectedSortType={selectedSortType}
              onSelectSortType={setSelectedSortType}
              searchInputValue={searchInputValue}
              searchInputPlaceholder={t("searchCustomer")}
              onClearSearchIput={() => setSearchInputValue("")}
              onChangeSearchInput={(value) => setSearchInputValue(value)}
            />
            <div className={classes.actionsContainer}>
              <ButtonIcon
                imagePath={`icons/card-grid${displayGrid ? "-active" : ""}`}
                className={`${classes.btnGrid} ${
                  displayGrid && classes.btnActive
                }`}
                onClick={() => {
                  setDisplayGrid(true);
                  setSearchInputValue("");
                }}
                isSvg={true}
              />
              <ButtonIcon
                imagePath={`icons/tabs${!displayGrid ? "-active" : ""}`}
                className={!displayGrid ? classes.btnActive : ""}
                onClick={() => {
                  setDisplayGrid(false);
                  setSearchInputValue("");
                }}
                isSvg={true}
              />
            </div>
          </div>
          {customers?.length > 0 ? (
            !displayGrid ? (
              <div
                className={`${classes.scrollableContainer} ${classes.tableStyle}`}
              >
                <table className={classes.table}>
                  <thead>
                    <tr>
                      {tableHeaders.map((th) => (
                        <th key={th.id}>
                          <div
                            className={classes.thContainer}
                            onClick={() => arrangeTableCustomersHandler(th?.id)}
                          >
                            <span>{t(th.id)}</span>
                            <img
                              src={require("../../../assets/img/icons/arrows.png")}
                              alt={t("arrows")}
                            />
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {customers.map((customer, index) => (
                      <CustomerTableRow
                        key={customer.id}
                        index={index}
                        customer={customer}
                        onClick={() => navigate(`/customers/${customer?.id}`)}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <ul className={classes.cardList}>
                {customers.map((customer) => (
                  <CustomerCard
                    key={customer?.id}
                    customer={customer}
                    onClick={() => navigate(`/customers/${customer?.id}`)}
                  />
                ))}
              </ul>
            )
          ) : (
            <NoItemsView className={classes.noItems} text={t("noItemsRow")} />
          )}
        </>
      )}
    </>
  );
}

export default Customers;
