import { AUTHENTICATION_API } from "../ApiUrls";
import loaderCenter from "../LoaderCenter";

// Manager class responsible for handling authentication-related API calls
class AuthManager {
  // Method for user login API call
  loginApi = async (credentials) => {
    try {
      const response = await loaderCenter.post(
        AUTHENTICATION_API.LOGIN,
        credentials
      );

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err;
    }
  };

  logoutApi = async () => {
    try {
      const response = await loaderCenter.post(AUTHENTICATION_API.LOGOUT);

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err;
    }
  };

  resetPasswordApi = async (credentials) => {
    try {
      const response = await loaderCenter.post(
        AUTHENTICATION_API.RESET_PASSWORD,
        credentials
      );

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      throw err;
    }
  };

  checkIsFirstLoginApi = async (email) => {
    try {
      const response = await loaderCenter.post(
        AUTHENTICATION_API.CHECK_FIRST_LOGIN(email)
      );

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      throw err;
    }
  };

  getQrApi = async () => {
    try {
      const response = await loaderCenter.post(AUTHENTICATION_API.GET_QR);

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err;
    }
  };

  validateCodeApi = async (code) => {
    try {
      const response = await loaderCenter.post(
        AUTHENTICATION_API.VALIDATE_CODE,
        {
          userToken: code,
        }
      );

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err;
    }
  };

  forgotPasswordAPi = async (email) => {
    try {
      const response = await loaderCenter.post(
        AUTHENTICATION_API.FORGOT_PASSWORD,
        email
      );

      const responseData = await response.json();

      return responseData;
    } catch (err) {
      return err;
    }
  };
}

const authManager = new AuthManager(); // Instantiate AuthManager

export default authManager; // Export the instantiated AuthManager for use in the application
