import classes from "./HistoryList.module.scss";
import Circle from "../ui/circle/Circle";
import { t } from "i18next";

function HistoryList({
  dataSource,
  className,
  maxLength = dataSource?.length,
}) {
  const length = dataSource?.length;

  const getHistoryAction = (historyAction) => {
    let action = "";
    switch (historyAction) {
      case "activate":
        action = "activated";
        break;
      case "suspend":
        action = "suspended";
        break;
      case "cease":
        action = "ceased";
        break;
      case "cancel":
        action = "cancelled";
        break;
      default:
        action = "";
        break;
    }
    return action;
  };

  return (
    <ul className={`${classes.list} ${className}`}>
      {dataSource?.map(
        (history, i) =>
          i < maxLength && (
            <li key={i}>
              <div className={classes.historySpan}>
                <Circle
                  color={"var(--primary-400)"}
                  className={classes.circle}
                />
                <span>
                  {t("history_subscription", {
                    plan: history?.plan,
                    type: t(history?.type) || "",
                    action: getHistoryAction(history?.action),
                    dateTime: history?.datetime,
                  })}
                </span>
              </div>
              {i < (length < maxLength ? length - 1 : maxLength - 1) && (
                <div className={classes.dash} />
              )}
            </li>
          )
      )}
    </ul>
  );
}

export default HistoryList;
