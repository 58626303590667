import { forwardRef } from "react";
import classes from "./Button.module.scss";

const Button = forwardRef(
  ({ type, title, className, onClick, isDisabled }, ref) => {
    return (
      <button
        ref={ref}
        type={type}
        className={`${classes.button} ${className} ${
          isDisabled && classes.disabled
        }`}
        onClick={!isDisabled ? onClick : () => {}}
        disabled={isDisabled}
      >
        {title}
      </button>
    );
  }
);

export default Button;
