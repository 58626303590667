import { CUSTOMER_API } from "../ApiUrls";
import loaderCenter from "../LoaderCenter";

let CUSTOMERS = [],
  ORIGINAL_CUSTOMERS_SUB = [];

class CustomerManager {
  getCustomersApi = async (signal) => {
    try {
      const response = await loaderCenter.get(CUSTOMER_API.CUSTOMERS, signal);

      const responseData = await response.json();
      CUSTOMERS = responseData.data;
      return responseData;
    } catch (err) {
      throw err;
    }
  };

  getCustomersWithSubscriptions = async () => {
    try {
      const response = await loaderCenter.get(
        CUSTOMER_API.CUSTOMERS_SUBSCRIPTIONS
      );

      const responseData = await response.json();
      const data = responseData?.data;

      responseData.data = data?.filter((c) => c.subscriber.length > 0);
      ORIGINAL_CUSTOMERS_SUB = responseData?.data;

      return responseData;
    } catch (err) {
      return err;
    }
  };

  getOriginalCustomersWithSubscription = () => {
    return ORIGINAL_CUSTOMERS_SUB;
  };

  getTableHeaders() {
    const ths = [];
    if (CUSTOMERS && CUSTOMERS.length > 0) {
      Object.keys(CUSTOMERS[0]).forEach((key) => {
        if (key !== "id") {
          ths.push({ id: key });
        }
      });
    }
    return ths;
  }

  getCustomers() {
    return CUSTOMERS;
  }

  getCustomerById(customerId) {
    return CUSTOMERS.find((customer) => customer.id === +customerId);
  }

  searchLocally(inputValue) {
    const customers = JSON.parse(JSON.stringify(CUSTOMERS));
    return customers?.filter((c) =>
      c?.customerName?.toLowerCase()?.includes(inputValue?.toLowerCase())
    );
  }
}

const customerManager = new CustomerManager();

export default customerManager;
