import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { t } from "i18next";

import classes from "./MovieModal.module.scss";
import Modal from "../../ui/modal/Modal";
import ContentModel from "../../../models/ContentModel";
import CustomInput from "../../ui/inputs/customInput/CustomInput";
import Circle from "../../ui/circle/Circle";
import CustomSelectInput from "../../ui/inputs/customSelectInput/CustomSelectInput";
import Line from "../../ui/line/Line";
import Button from "../../ui/button/Button";
import DateHelper from "../../../utils/DateHelper";
import VodSeriesCommunContents from "../../communContents/VodSeriesCommunContents";
import movieManager from "../../../backend/managers/MovieManager";
import Loader from "../../ui/loader/Loader";
import { castActions } from "../../../store/reducers/Cast-slice";

function MovieModal({ isModalOpen, onClose, isEditing, movie, disabled }) {
  const [updatedMovie, setUpdatedMovie] = useState(movie);

  const modelContent = new ContentModel(updatedMovie);
  const data = modelContent.data;

  const [titles, setTitles] = useState({});
  const [descriptions, setDescriptions] = useState({});
  const [posterFile, setPosterFile] = useState();
  const [backgroundFile, setBackgroundFile] = useState();
  const [landscapeFile, setLandscapeFile] = useState();
  const [previewFile, setPreviewFile] = useState();
  const [price, setPrice] = useState(0);
  const [movieUrl, setMovieUrl] = useState("");
  const [trailerUrl, setTrailerUrl] = useState("");
  const [showMembers, setShowMembers] = useState(false);
  const [duration, setDuration] = useState({ h: "00", min: "00", s: "00" });
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [selectedCatValues, setSelectedCatValues] = useState([]);
  const [rating, setRating] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    // let name = {},
    //   description = {};
    // Object.keys(data.translation).forEach((lang) => {
    //   name = {
    //     ...name,
    //     [lang]: titles[lang] || data?.translation[lang]?.name,
    //   };
    //   description = {
    //     ...description,
    //     [lang]: descriptions[lang] || data?.translation[lang]?.description,
    //   };
    // });
    // vodManager.editVodApi(data.id, data);
    onClose();
  };

  const closeModalhandler = () => {
    onClose();
  };

  const posterFileChangeHandler = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setPosterFile(URL.createObjectURL(file));
    }
  };

  const landscapeFileChangeHandler = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setLandscapeFile(URL.createObjectURL(file));
    }
  };

  const previewFileChangeHandler = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setPreviewFile(URL.createObjectURL(file));
    }
  };

  const backgroundFileChangeHandler = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setBackgroundFile(URL.createObjectURL(file));
    }
  };

  const closeCardsHandler = () => {
    setShowMembers(false);
  };

  const selectItemsHandler = (selectedItems) => {
    setSelectedCatValues(selectedItems);
  };

  const onTitlesChangeHandler = (e) => {
    setTitles({ ...titles, [selectedLanguage]: e.target.value });
  };

  const onDescriptionsChangeHandler = (e) => {
    setDescriptions({
      ...descriptions,
      [selectedLanguage]: e.target.value,
    });
  };

  const fetchData = async () => {
    if (isEditing) {
      setIsLoading(true);
      const vod = await movieManager.getVodById(movie?.id);
      setUpdatedMovie(vod);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    isEditing && fetchData();
  }, [isEditing]);

  useEffect(() => {
    setRating(isEditing ? data?.rating : 0);
    setSelectedCatValues(isEditing ? data?.genre : []);
    setPosterFile();
    setBackgroundFile();
    const objectTitles = {},
      objectDescription = {};
    isEditing &&
      Object.keys(data?.title).forEach((lang) => {
        objectTitles[lang] = modelContent.getNameByLang(lang);
        objectDescription[lang] = modelContent.getDescriptionByLang(lang);
      });
    setTitles(isEditing ? objectTitles : { en: "", fr: "", ar: "" });
    setDescriptions(isEditing ? objectDescription : { en: "", fr: "", ar: "" });
    setSelectedLanguage("en");
    setPrice(isEditing ? data?.price : 0);
    setMovieUrl(
      isEditing
        ? data?.media?.find((media) => media?.type === "hls_fairplay_spa")?.url
        : ""
    );
    setTrailerUrl(isEditing ? data?.trailer : "");
    setShowMembers(false);
    const durationInSeconds = data?.duration;
    const duration = DateHelper.getDuration(durationInSeconds, "seconds");
    const h = String(duration.hours()).padStart(2, "0");
    const m = String(duration.minutes()).padStart(2, "0");
    const s = String(duration.seconds()).padStart(2, "0");
    setDuration(
      isEditing
        ? {
            h: h,
            min: m,
            s: s,
          }
        : { h: "00", min: "00", s: "00" }
    );
    dispatch(
      castActions.setCastData({
        actors: modelContent.getActors(),
        directors: modelContent.getDirectors(),
        isActorsExpanded: false,
        isDirectorsExpanded: false,
      })
    );
    // eslint-disable-next-line
  }, [isModalOpen, isEditing, updatedMovie]);

  const posterImage = posterFile
    ? posterFile
    : isEditing
    ? modelContent.getPosterImage()
    : require("../../../assets/img/card-content-poster.png");

  const backgroundImage = backgroundFile
    ? backgroundFile
    : isEditing
    ? modelContent.getBackgroundImage()
    : require("../../../assets/img/card-content-background.png");

  const landscapeImage = landscapeFile
    ? landscapeFile
    : isEditing
    ? modelContent.getLandscapeImage()
    : require("../../../assets/img/card-content-background.png");

  const previewImage = previewFile
    ? previewFile
    : isEditing
    ? modelContent.getPreviewImage()
    : require("../../../assets/img/card-content-background.png");

  const categories = movieManager.getCategories();
  const languages = data?.languages || [];
  const subtitles = data?.subtitles?.split(",") || [];
  const format = data?.format;
  const currency = data?.currency;
  // const langListDataSource = Object.keys(data?.title);
  const langListDataSource = ["en", "ar"];
  const offers = data?.package;
  const addOns = data?.addOn?.filter((e) => e?.isOneTime === false);
  const verticals = data?.verticals;
  const ppv = data?.addOn?.filter((e) => e?.isOneTime === true);
  const imagesData = {
    posterImage,
    onPosterFileChange: posterFileChangeHandler,
    landscapeImage,
    onLandscapeFileChange: landscapeFileChangeHandler,
    backgroundImage,
    onBackgroundFileChange: backgroundFileChangeHandler,
    previewImage,
    onPreviewFileChange: previewFileChangeHandler,
  };

  return (
    <Modal
      className={classes.modal}
      isOpen={isModalOpen}
      onClose={closeModalhandler}
      top="1rem"
    >
      <h2>
        {isEditing
          ? t("modify") + " " + modelContent.getNameByLang()
          : t("newMovie")}
      </h2>
      {isLoading ? (
        <Loader />
      ) : (
        <form onSubmit={submitHandler} onClick={closeCardsHandler}>
          <VodSeriesCommunContents
            langListDataSource={langListDataSource}
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
            titles={titles}
            onTitlesChange={onTitlesChangeHandler}
            descriptions={descriptions}
            onDescriptionsChange={onDescriptionsChangeHandler}
            imagesData={imagesData}
            rating={rating}
            setRating={setRating}
            categories={categories}
            languages={languages}
            subtitles={subtitles}
            initialSelectedItems={selectedCatValues}
            onSelectItems={selectItemsHandler}
            disabled={disabled}
            format={format}
            trailerUrl={trailerUrl}
            offers={offers}
            addOns={addOns}
            verticals={verticals}
            ppv={ppv}
            contentId={data?.id}
            onRefresh={fetchData}
          >
            <div className={classes.durationRow}>
              <label>{t("duration")}</label>
              <div className={classes.timeContainer}>
                <CustomInput
                  label={t("hour")}
                  className={classes.timeUnit}
                  placeholder={"00"}
                  type={"number"}
                  value={duration.h}
                  onChange={() => {}}
                  disabled={disabled}
                />
                <div className={classes.colon}>
                  <Circle color={"var(--grey-600)"} className={classes.dot} />
                  <Circle color={"var(--grey-600)"} className={classes.dot} />
                </div>
                <CustomInput
                  label={t("min")}
                  className={classes.timeUnit}
                  placeholder={"00"}
                  type={"number"}
                  value={duration.min}
                  onChange={() => {}}
                  disabled={disabled}
                />
                <div className={classes.colon}>
                  <Circle color={"var(--grey-600)"} className={classes.dot} />
                  <Circle color={"var(--grey-600)"} className={classes.dot} />
                </div>
                <CustomInput
                  label={t("sec")}
                  className={classes.timeUnit}
                  placeholder={"00"}
                  type={"number"}
                  value={duration.s}
                  onChange={() => {}}
                  disabled={disabled}
                />
              </div>
            </div>
            {price && (
              <div className={`${classes.gridRow}`}>
                <label>{t("price")}</label>
                <div className={classes.price}>
                  <CustomInput
                    className={classes.customInput}
                    type={"number"}
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    disabled={disabled}
                  />
                  <label>{t("currency")}</label>
                  {disabled ? (
                    <CustomInput
                      disabled={true}
                      value={currency}
                      className={classes.inputHeight}
                    />
                  ) : (
                    <CustomSelectInput
                      className={classes.select}
                      options={[currency]}
                      disabled={disabled}
                    />
                  )}
                </div>
              </div>
            )}
            <div className={`${classes.gridRow} ${classes.urlMovie}`}>
              <label>{t("urlMovie")}</label>
              <CustomInput
                className={classes.customInput}
                placeholder={t("https")}
                value={movieUrl || ""}
                onChange={(e) => setMovieUrl(e.target.value)}
                disabled={disabled}
              />
            </div>
          </VodSeriesCommunContents>
          <Line className={classes.line} />
          <div className={classes.actionContainer}>
            <Button
              title={t(disabled ? "close" : "btnSave")}
              type={"submit"}
              className={classes.closeBtn}
            />
          </div>
        </form>
      )}
    </Modal>
  );
}

export default MovieModal;
