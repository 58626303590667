import { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import classes from "./DraggableItem.module.scss";

import ButtonIcon from "../../ui/buttonIcon/ButtonIcon";
import dragIcon from "../../../assets/img/icons/drag.png";
import CustomInput from "../../ui/inputs/customInput/CustomInput";
import ContentHelper from "../../../utils/ContentHelper";

function DraggableItem({
  index,
  itemClassName,
  data,
  iconsList,
  isDragging,
  draggingIndex,
  hoverIndex,
  placeholder,
  initialValue,
  onInputChange,
  title,
  className,
  draggingClassName,
  onDragOverItem,
}) {
  const inputRef = useRef(null);

  const [inputValue, setInputValue] = useState(initialValue || "");

  const changeInputHandler = (e) => {
    const value = e?.target?.value;
    setInputValue(value);
    onInputChange(value);
  };

  const getDisplayedIndex = () => {
    if (hoverIndex !== null && draggingIndex !== null) {
      // Dragging upwards
      if (draggingIndex > hoverIndex) {
        if (index === draggingIndex) return hoverIndex + 1; // Dragged item takes the hover item's index
        if (index >= hoverIndex && index < draggingIndex) return index + 2; // Shift other items' indices up by 1
      }
      // Dragging downwards
      if (draggingIndex < hoverIndex) {
        if (index === draggingIndex) return hoverIndex + 1; // Dragged item takes the hover item's index
        if (index > draggingIndex && index <= hoverIndex) return index; // Maintain indices for items above
      }
    }
    return index + 1; // Default case: start index from 1
  };

  useEffect(() => {
    if (isDragging && inputRef.current) {
      inputRef.current.blur();
    }
  }, [isDragging]);

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  return (
    <div className={`${classes.container} ${className}`}>
      <div
        className={`${classes.draggableItem} ${itemClassName} ${
          isDragging ? `${classes.dragging} ${draggingClassName}` : ""
        }`}
      >
        {!isDragging && (
          <div className={`${classes.dragBtnWrapper}`}>
            <img src={dragIcon} alt={"dragIcon"} className={classes.dragIcon} />
            <span> {getDisplayedIndex()}</span>
          </div>
        )}

        <div className={classes.contentText}>
          <CustomInput
            className={classes.customInput}
            inputClassName={`${classes.input} ${
              isDragging && classes.draggingInput
            }`}
            placeholder={placeholder}
            // value={
            //   title
            //     ? isDragging
            //       ? `${index + 1}. ${ContentHelper.getNameByLang(data)}`
            //       : ContentHelper.getNameByLang(data)
            //     : inputValue
            // }
            value={
              title
                ? isDragging
                  ? `${getDisplayedIndex()}. ${ContentHelper.getNameByLang(data)}`
                  : ContentHelper.getNameByLang(data)
                : inputValue
            }
            onChange={changeInputHandler}
            disabled={title}
            ref={inputRef}
          />
        </div>
      </div>
      <div className={classes.actionBtnWrapper}  style={{ flexBasis: `${iconsList?.length * 30 + 10}px` }}>
        {!isDragging &&
          iconsList?.map((icon) => (
            <ButtonIcon
              key={icon.id}
              className={classes.actionBtn}
              imagePath={icon.imagePath}
              activeImagePath={icon.activeImagePath}
              onClick={icon.onClick}
            />
          ))}
      </div>
    </div>
  );
}

export default DraggableItem;
