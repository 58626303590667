import Cookies from "js-cookie";

class LoaderCenter {
  getHeaders() {
    return {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
      Authorization: `${
        Cookies.get("token") || Cookies.get("temp_token")
      }`,
    };
  }

  get(endpoint, signal = null) {
    // console.log(Cookies.get("token"));
    return fetch(endpoint, {
      method: "GET",
      headers: this.getHeaders(),
      signal: signal,
    });
  }

  post(endpoint, data, signal = null) {
    return fetch(endpoint, {
      method: "POST",
      headers: this.getHeaders(),
      body: JSON.stringify(data),
      signal: signal,
    });
  }
}

const loaderCenter = new LoaderCenter();

export default loaderCenter;
