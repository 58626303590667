import { useEffect, useRef, useState } from "react";
import { t } from "i18next";

import classes from "./MessageContent.module.scss";
import LanguagesList from "../../languagesList/LanguagesList";
import Line from "../../ui/line/Line";
import ButtonIcon from "../../ui/buttonIcon/ButtonIcon";
// import DateHelper from "../../../utils/DateHelper";
import SearchInput from "../../ui/inputs/searchInput/SearchInput";
import Loader from "../../ui/loader/Loader";
import Message from "../../../models/Message";
import ExpandableItem from "./ExpandableItem";
import messageManager from "../../../backend/managers/MessageManager";
// import Circle from "../../ui/circle/Circle";

function MessageContent({
  messageData,
  onDuplicateMessage,
  onOpenEditMessage,
  onRefresh,
  isLoading,
  onDeleteMessage,
  hideActions,
}) {
  const messageModel = new Message(messageData);
  const data = messageModel?.messageData;

  const initialMessageStatus = messageModel.getStatuses() || {
    all: [],
    read: [],
    unread: [],
  };

  const [selectedCustomerMessageStatus, setSelectedCustomerMessageStatus] =
    useState("all");
  const [searchInputValue, setSearchInputValue] = useState("");
  const [selectedLang, setSelectedLang] = useState("en");
  const [isPinLoading, setIsPinLoading] = useState(false);
  const [messageStatus, setMessageStatus] = useState(initialMessageStatus);

  const ref = useRef();

  // Fetch message status from the MessageManager
  // const messageStatus = messageModel.getStatuses();

  // Calculate read and unread percentages
  let readPercentage = Math.round((data?.read / data?.total) * 100);
  let unreadPercentage = 100 - readPercentage;

  // Extract message details
  // const date = DateHelper.formatDate(
  //   message?.timeToSend,
  //   `DD MMM yyyy : HH[h]mm`
  // );
  // const title = message?.getContentByLang()?.name;
  // const body = message?.getContentByLang()?.body;
  const title = messageModel?.getTitleByLang(selectedLang);
  const body = messageModel?.getContentByLang(selectedLang);
  const type = data?.type;
  const recipientsNb = data?.total;
  const isPinned = data?.pin;
  const langs = Object.keys(data?.title || {}) || ["en", "fr", "ar"];

  // Split the body of the message by new lines
  const splittedBody = body?.split("\n");

  // Determine the icon name and max nb of chars based on the message type

  const iconName = messageManager.getMessageInfoByType(type).icon;
  const nbOfChars = messageManager.getMessageInfoByType(type).maxLength || 0;

  // Function to toggle the pinned status of the message
  const togglePinHandler = async () => {
    const data = {
      id: messageData?.id,
      type: messageData?.type,
      pin: !messageData?.pin,
      title: messageData?.title,
      content: messageData?.content,
    };
    setIsPinLoading(true);
    const result = await messageManager.updateMessageApi(data);
    if (result?.status === 0) {
      onRefresh();
    }
    setIsPinLoading(false);
  };

  const searchMessageHandler = (inputValue) => {
    setSearchInputValue(inputValue);
  };

  const clearInputHandler = () => {
    setSearchInputValue("");
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setMessageStatus((prevState) => {
        Object.keys(prevState).forEach((status) => {
          prevState[status] = initialMessageStatus[status].filter((item) =>
            item?.name
              ?.toLocaleLowerCase()
              ?.includes(searchInputValue?.toLocaleLowerCase())
          );
        });
        return { ...prevState };
      });
    }, 500);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, [searchInputValue]);

  // Effect to set initial state and reset status on message change
  useEffect(() => {
    setSelectedLang("en");
    setSelectedCustomerMessageStatus("all");
    setSearchInputValue("");
    setMessageStatus(initialMessageStatus);
    // eslint-disable-next-line
  }, [messageData]);

  return isLoading ? (
    <div className={classes.loaderContainer}>
      <Loader positionNotFixed={true} className={classes.loader} />
    </div>
  ) : (
    <div className={classes.messageContent}>
      <div className={classes.infoSection} ref={ref}>
        {ref.current?.clientWidth && (
          <LanguagesList
            className={classes.langList}
            dataSource={langs}
            selectedLang={selectedLang}
            setSelectedLang={setSelectedLang}
            width={ref.current?.clientWidth - 16}
          />
        )}
        {/* Info section */}
        <div className={classes.contentSection}>
          <div className={classes.infoRow}>
            <div className={classes.infoRow}>
              <h3 onClick={onOpenEditMessage}>{title}</h3>
              {/* <Circle className={classes.dot} /> */}
              {/* <span>{date}</span> */}
            </div>
            <div className={classes.infoRow}>
              {isPinLoading ? (
                <Loader positionNotFixed={true} className={classes.pinLoader} />
              ) : (
                <img
                  alt="type"
                  src={require(`../../../assets/img/message/message-pinned${
                    isPinned ? "-active" : ""
                  }.png`)}
                  className={classes.pinned}
                  onClick={togglePinHandler}
                />
              )}
              <img
                alt="type"
                src={require(`../../../assets/img/message/${iconName}.png`)}
              />
            </div>
          </div>
          <div className={classes.recipients}>
            {recipientsNb} {t("recipients")}
          </div>
          {/* Display message body */}
          <div onClick={onOpenEditMessage}>
            {splittedBody?.map((p) => (
              <p key={Math.random()}>{p}</p>
            ))}
          </div>
          {/* Actions container */}
          <div className={classes.actionsContainer}>
            {/* Horizontal line */}
            <span className={classes.nbOfChars}>
              {body?.length} / {nbOfChars}
            </span>
            {!hideActions && (
              <>
                <Line />
                {/* Button actions */}
                <div className={classes.actions}>
                  {/* <ButtonIcon
                      imagePath="buttons/submit"
                      activeImagePath="buttons/submit-active"
                      /> */}
                  <ButtonIcon
                    imagePath="buttons/duplicate"
                    activeImagePath="buttons/duplicate-active"
                    onClick={onDuplicateMessage}
                  />
                  <ButtonIcon
                    imagePath="buttons/delete"
                    activeImagePath="buttons/delete-active"
                    onClick={onDeleteMessage}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Customer messages section */}
      {initialMessageStatus.all.length > 0 && (
        <div className={classes.customersSection}>
          {/* Status options */}
          <div className={classes.statusContainer}>
            {/* Status options with percentages */}
            <div>
              <span
                onClick={() => setSelectedCustomerMessageStatus("all")}
                className={
                  selectedCustomerMessageStatus === "all" ? classes.active : ""
                }
              >
                {t("all")}
              </span>
              <span
                onClick={() => setSelectedCustomerMessageStatus("read")}
                className={
                  selectedCustomerMessageStatus === "read" ? classes.active : ""
                }
              >
                {t("read") + "(" + readPercentage + "%)"}
              </span>
              <span
                onClick={() => setSelectedCustomerMessageStatus("unread")}
                className={
                  selectedCustomerMessageStatus === "unread"
                    ? classes.active
                    : ""
                }
              >
                {t("unread") + "(" + unreadPercentage + "%)"}
              </span>
            </div>
            <SearchInput
              className={classes.searchInput}
              value={searchInputValue}
              onChangeInput={searchMessageHandler}
              onClear={clearInputHandler}
            />
          </div>

          {/* List of customer messages */}
          <ul className={classes.customersList}>
            {messageStatus[selectedCustomerMessageStatus].map((customer) => (
              <ExpandableItem
                key={customer?.id}
                customer={customer}
                selectedCustomerMessageStatus={selectedCustomerMessageStatus}
              />
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default MessageContent;
