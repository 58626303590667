import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { t } from "i18next";

import classes from "./VodSeriesCommunContents.module.scss";
import CustomInput from "../ui/inputs/customInput/CustomInput";
import LanguagesList from "../languagesList/LanguagesList";
import ButtonIcon from "../ui/buttonIcon/ButtonIcon";
import CustomSelectInput from "../ui/inputs/customSelectInput/CustomSelectInput";
import PickerView from "../pickerViews/pickerView/PickerView";
import Rating from "../ui/rating/Rating";
import Cast from "../cast/Cast";
import Button from "../ui/button/Button";
import LinkContentsToVerticalsAndPackagesModal from "../modals/linkContentsToVerticalsAndPackagesModal/LinkContentsToVericalsAndPackagesModal";
import contentManager from "../../backend/managers/ContentManager";
import useElementWidth from "../../hooks/useElementWidth";
import ExpandableMenu from "../expandables/expandableMenu/ExpandableMenu";
import { errorActions } from "../../store/reducers/Error-slice";

function VodSeriesCommunContents({
  selectedLanguage,
  setSelectedLanguage,
  imagesData,
  titles,
  onTitlesChange,
  descriptions,
  onDescriptionsChange,
  rating,
  categories,
  languages,
  subtitles,
  children,
  initialSelectedItems,
  onSelectItems,
  setRating,
  disabled,
  format,
  langListDataSource,
  trailerUrl,
  verticals,
  offers,
  addOns,
  ppv,
  contentId,
  onRefresh,
  expContainerClassName,
}) {
  const [openLinkContentsModal, setOpenLinkContentsModal] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);

  const ref = useRef();
  const divWidth = useElementWidth(ref);

  const dispatch = useDispatch();

  const openEditAttachmentHandler = (e) => {
    e.preventDefault();
    setOpenLinkContentsModal(true);
  };

  const submitLinkContentsHandler = async (
    verticalIdsTobeAdded,
    offerIdsTobeAdded,
    addonWithPpvIdsToBeAdded,
    verticalIdsTobeRemoved,
    offerIdsTobeRemoved,
    addonWithPpvIdsToBeRemoved
  ) => {
    setIsModalLoading(true);
    if (
      verticalIdsTobeAdded?.length > 0 ||
      offerIdsTobeAdded?.length > 0 ||
      addonWithPpvIdsToBeAdded?.length > 0
    ) {
      const result1 = await contentManager.attachContentApi({
        contentId,
        verticalIds: verticalIdsTobeAdded,
        planIds: offerIdsTobeAdded,
        addOnIds: addonWithPpvIdsToBeAdded,
      });
      if (result1?.status !== 0) {
        dispatch(errorActions.setErrorMessage(result1?.message || result1));
        setIsModalLoading(false);
        return;
      }
    }
    if (
      verticalIdsTobeRemoved?.length > 0 ||
      offerIdsTobeRemoved?.length > 0 ||
      addonWithPpvIdsToBeRemoved?.length > 0
    ) {
      const result2 = await contentManager.detachContentApi({
        contentId,
        verticalIds: verticalIdsTobeRemoved,
        planIds: offerIdsTobeRemoved,
        addOnIds: addonWithPpvIdsToBeRemoved,
      });
      if (result2?.status !== 0) {
        dispatch(errorActions.setErrorMessage(result2?.message || result2));
        setIsModalLoading(false);
        return;
      }
    }
    if (
      verticalIdsTobeAdded?.length === 0 &&
      offerIdsTobeAdded?.length === 0 &&
      addonWithPpvIdsToBeAdded?.length === 0 &&
      verticalIdsTobeRemoved?.length === 0 &&
      offerIdsTobeRemoved?.length === 0 &&
      addonWithPpvIdsToBeRemoved?.length === 0
    ) {
      setIsModalLoading(false);
      return;
    }
    setIsModalLoading(false);
    setOpenLinkContentsModal(false);
    onRefresh();
  };

  return (
    <>
      <div
        className={`${classes.expContainer} ${expContainerClassName}`}
        ref={ref}
      >
        <ExpandableMenu
          title={t("information")}
          initialExpandedValue={true}
          containerClassName={classes.expMenuClassName}
        >
          <div className={classes.container}>
            <div className={`${classes.gridRow} ${classes.noMargin}`}>
              <div className={classes.langListContainer}>
                {divWidth && (
                  <LanguagesList
                    dataSource={langListDataSource}
                    selectedLang={selectedLanguage}
                    setSelectedLang={setSelectedLanguage}
                    width={divWidth - 356} //padding of expContainer, container and width of first column od the grid layout
                  />
                )}
              </div>
            </div>
            <div>
              <CustomInput
                row
                label={t("title")}
                className={classes.titleInput}
                value={titles[selectedLanguage] || ""}
                onChange={onTitlesChange}
                disabled={disabled}
              />
              <div className={classes.textAreaRow}>
                <label>{t("summary")}</label>
                <div>
                  <textarea
                    rows={5}
                    maxLength={350}
                    value={descriptions[selectedLanguage] || ""}
                    onChange={onDescriptionsChange}
                    disabled={disabled}
                    className={disabled ? classes.disabled : ""}
                  />
                  <div className={`${classes.row} ${classes.charsContainer}`}>
                    <span className={classes.abc}>{t("abc") + ": "}</span>
                    <span className={classes.chars}>
                      {descriptions[selectedLanguage]?.length || 0}/350
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.contentContainer}>
              <Rating
                disabled={disabled}
                selectedStars={rating}
                setSelectedStars={setRating}
              />
              <div
                className={`${classes.cast} ${classes.gridRow} ${classes.alignStart}`}
              >
                <label>{t("cast_crew")}</label>
                <div className={classes.castContainer}>
                  <Cast disabled={disabled} />
                </div>
              </div>
              {categories?.length > 0 && (
                <div className={`${classes.gridRow} ${classes.alignStart}`}>
                  <label>{t("themes")}</label>
                  <div className={classes.pickerViewContainer}>
                    <PickerView
                      grid={true}
                      dataSource={categories}
                      initialSelectedItems={initialSelectedItems}
                      allowMultipleSelections={true}
                      imageName="checkbox-checked"
                      onSelectItems={onSelectItems}
                      pickerCellHeight={35}
                      pickerCellImageHeight={20}
                      pickerCellClassName={classes.pickerCell}
                      pickerViewClassName={classes.pickerView}
                      maxSelectedItemsLength={4}
                      isDisabled={disabled}
                    />
                    {!disabled && <span>{t("only_4_themes")}</span>}
                  </div>
                </div>
              )}
              <div className={`${classes.marginBottom} ${classes.gridRow}`}>
                <label>{t("languages")}</label>
                {disabled ? (
                  <div>
                    {languages?.map((l) => (
                      <CustomInput
                        key={l}
                        className={classes.input}
                        disabled={true}
                        value={l}
                      />
                    ))}
                  </div>
                ) : (
                  <div className={classes.row}>
                    <CustomSelectInput
                      className={classes.selectLang}
                      options={languages}
                      disabled={disabled}
                    />
                    {!disabled && (
                      <ButtonIcon
                        imagePath={"buttons/add-purple-rounded"}
                        activeImagePath={"buttons/add-purple-rounded-active"}
                        isDisabled={disabled}
                        disabledImagePath={
                          "buttons/add-purple-rounded-disabled"
                        }
                      />
                    )}
                  </div>
                )}
              </div>
              {subtitles?.length > 0 && (
                <div className={`${classes.marginBottom} ${classes.gridRow}`}>
                  <label>{t("subtitles")}</label>
                  {disabled ? (
                    <div>
                      {subtitles?.map((sub) => (
                        <CustomInput
                          key={sub}
                          className={classes.input}
                          disabled={true}
                          value={sub}
                        />
                      ))}
                    </div>
                  ) : (
                    <div className={classes.row}>
                      <CustomSelectInput
                        className={classes.selectLang}
                        options={subtitles}
                        disabled={disabled}
                      />
                      {!disabled && (
                        <ButtonIcon
                          imagePath={"buttons/add-purple-rounded"}
                          activeImagePath={"buttons/add-purple-rounded-active"}
                          isDisabled={disabled}
                          disabledImagePath={
                            "buttons/add-purple-rounded-disabled"
                          }
                        />
                      )}
                    </div>
                  )}
                </div>
              )}
              {format && (
                <div className={`${classes.marginBottom} ${classes.gridRow}`}>
                  <label>{t("format")}</label>
                  {disabled ? (
                    <CustomInput
                      value={format}
                      disabled={true}
                      className={classes.input}
                    />
                  ) : (
                    <CustomSelectInput
                      className={classes.selectLang}
                      options={[format]}
                      disabled={disabled}
                    />
                  )}
                </div>
              )}
              <div className={`${classes.gridRow}`}>
                <label>{t("urlTrailer")}</label>
                <CustomInput
                  className={classes.customInput}
                  placeholder={t("https")}
                  value={trailerUrl || ""}
                  // onChange={(e) => setTrailerUrl(e.target.value)}
                  disabled={disabled}
                />
              </div>
              {offers?.length > 0 && (
                <div
                  className={`${classes.gridRow} ${
                    offers?.length > 1 ? classes.alignStart : ""
                  }`}
                >
                  <label>{t("offers")}</label>
                  <div>
                    {offers?.map((offer, index) => (
                      <CustomInput
                        className={classes.input}
                        key={offer?.id || index}
                        value={offer?.sku_name}
                        disabled={true}
                      />
                    ))}
                  </div>
                </div>
              )}
              {addOns?.length > 0 && (
                <div
                  className={`${classes.gridRow} ${
                    addOns?.length > 1 ? classes.alignStart : ""
                  }`}
                >
                  <label>{t("add_ons")}</label>
                  <div>
                    {addOns?.map((addOn) => (
                      <CustomInput
                        className={classes.input}
                        key={addOn?.id}
                        value={addOn?.skuName}
                        disabled={true}
                      />
                    ))}
                  </div>
                </div>
              )}
              {ppv?.length > 0 && (
                <div
                  className={`${classes.gridRow} ${
                    ppv?.length > 1 ? classes.alignStart : ""
                  }`}
                >
                  <label>{t("ppv")}</label>
                  <div>
                    {ppv?.map((p) => (
                      <CustomInput
                        className={classes.input}
                        key={p?.id}
                        value={p?.skuName}
                        disabled={true}
                      />
                    ))}
                  </div>
                </div>
              )}
              {verticals?.length > 0 && (
                <div
                  className={`${classes.gridRow} ${
                    verticals?.length > 1 ? classes.alignStart : ""
                  }`}
                >
                  <label>{t("verticals")}</label>
                  <div>
                    {verticals?.map((vertical) => (
                      <CustomInput
                        className={classes.input}
                        key={vertical?.elementId}
                        value={vertical?.value}
                        disabled={true}
                      />
                    ))}
                  </div>
                </div>
              )}
              <div className={`${classes.gridRow}`}>
                <Button
                  className={classes.btn}
                  title={t("edit_attachments")}
                  onClick={openEditAttachmentHandler}
                />
              </div>
              {children}
            </div>
          </div>
        </ExpandableMenu>
        <ExpandableMenu
          title={t("visuals")}
          containerClassName={classes.expMenuClassName}
        >
          <div className={classes.imagesContainer}>
            <div className={classes.imageRowContainer}>
              <div>
                <span>{t("poster")}</span>
                <div className={classes.fileContainer}>
                  {!disabled && (
                    <ButtonIcon
                      imagePath={"icons/modify"}
                      className={`${classes.btnEdit} ${classes.btnPoster}`}
                    />
                  )}
                  <img
                    className={classes.poster}
                    alt="poster"
                    src={imagesData?.posterImage}
                    style={{ cursor: disabled ? "default" : "pointer" }}
                  />
                  {!disabled && (
                    <input
                      type="file"
                      className={classes.fileInput}
                      onChange={imagesData?.onPosterFileChange}
                    />
                  )}
                </div>
              </div>
              <div>
                <span>{t("preview")}</span>
                <div className={classes.fileContainer}>
                  {!disabled && (
                    <ButtonIcon
                      imagePath={"icons/modify"}
                      className={classes.btnEdit}
                    />
                  )}
                  <img
                    className={classes.preview}
                    alt="preview"
                    src={imagesData?.previewImage}
                    style={{ cursor: disabled ? "default" : "pointer" }}
                  />
                  {!disabled && (
                    <input
                      type="file"
                      className={classes.fileInput}
                      onChange={imagesData?.onPreviewFileChange}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={classes.imageRowContainer}>
              <div>
                <span>{t("landscape")}</span>
                <div className={classes.fileContainer}>
                  {!disabled && (
                    <ButtonIcon
                      imagePath={"icons/modify"}
                      className={classes.btnEdit}
                    />
                  )}
                  <img
                    className={classes.landscape}
                    alt="landscape"
                    src={imagesData?.landscapeImage}
                    style={{ cursor: disabled ? "default" : "pointer" }}
                  />
                  {!disabled && (
                    <input
                      type="file"
                      className={classes.fileInput}
                      onChange={imagesData?.onLandscapeFileChange}
                    />
                  )}
                </div>
              </div>
              <div>
                <span>{t("background")}</span>
                <div className={classes.fileContainer}>
                  {!disabled && (
                    <ButtonIcon
                      imagePath={"icons/modify"}
                      className={classes.btnEdit}
                    />
                  )}
                  <img
                    className={classes.background}
                    alt="background"
                    src={imagesData?.backgroundImage}
                    style={{ cursor: disabled ? "default" : "pointer" }}
                  />
                  {!disabled && (
                    <input
                      type="file"
                      className={classes.fileInput}
                      onChange={imagesData?.onBackgroundFileChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </ExpandableMenu>
      </div>
      {openLinkContentsModal && (
        <LinkContentsToVerticalsAndPackagesModal
          isModalOpen={openLinkContentsModal}
          onClose={() => setOpenLinkContentsModal(false)}
          onSubmit={submitLinkContentsHandler}
          title={t("linkContents")}
          isModalLoading={isModalLoading}
          initialData={{
            verticals: verticals,
            offers: offers,
            addons: addOns,
            ppv: ppv,
          }}
        />
      )}
    </>
  );
}

export default VodSeriesCommunContents;
